<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :block-content="false"
      :view-content="false"
      :columns="columns"
      :type="type"
      :selectable="false"
      :add-component-name="addComponentName"
      :delete-content="false"
      :edit-content="false"
      :add-type="addType"
      :post-content="false"
    >
      <template #cell(amount)="data">
        <div style="margin: 6px;">
          <span
            style="text-wrap: nowrap;"
          >
            {{ data.item.amount }}  {{ data.item.currency }}
          </span>
        </div>

      </template>
      <template #cell(action)="data">
        <slot
          name="actions"
          v-bind="data"
        >
          <span title="Reverse">
            <feather-icon
              icon="RepeatIcon"
              class="cursor-pointer mx-1"
              @click="
                $router.push({
                  name: 'Reverse Entries',
                  params: { id: data.item.id }
                })
              "
            />
          </span>
          <span title="View">
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              @click="
                $router.push({
                  name: 'PostEntry',
                  params: { id: data.item.id }
                })
              "
            />
          </span>

        </slot>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'accounting-entry/post',
      // addType: 'Create New Entry',
      // addComponentName: 'add-accounting-entry',
      // editComponent: 'edit-accounting-entry',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'code', label: 'Code' },
        { key: 'title', label: 'Title' },
        { key: 'date', label: 'Date' },
        { key: 'amount', label: 'Amount' },
        { key: 'office.name', label: 'Office' },
        { key: 'created_by', label: 'Created By' },
        { key: 'created_at', label: 'Created At' },
        // { key: 'posted_by', label: 'Title' },
        // { key: 'posted_at', label: 'Title' },
        { key: 'action', label: 'Actions' },
        // { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'accounting-entry/post?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log('Count in mounted:', this.role)
  // },

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

  <style></style>
